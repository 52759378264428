import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 56;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>
          <a
            href="https://www.youtube.com/watch?v=L2wThQljxcY"
            target="_blank"
            rel="noopener noreferrer"
          >
            録音した自分の声って高く聞こえる理由
          </a>
        </li>
        <li>釣行って「ちょうこう」って読むって知ってた？</li>
        <li>1 車がなくても釣りは楽しめる</li>
        <li>2 シーズンはやっぱり大切です</li>
        <li>3 ローカルルールを守ること</li>
        <li>
          ※
          <a
            href="https://www.youtube.com/watch?v=1q8mt7BXYnU"
            target="_blank"
            rel="noopener noreferrer"
          >
            NY州のルール
          </a>
          が変わったようで、再度確認したら現在は12インチ以上の魚は○匹まで、というルールの場所が多いようです
        </li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
